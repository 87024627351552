<template>
    <div class="ProductRecommendation" :class="{en:$route.params.lang === 'en',zh:$route.params.lang === 'zh'}">
        <div class="product-recommendation-container">

            <div class="ProductRecommendation_Warp">
                <div class="title">
                    <h3>{{$t('ProductRecommendation.Title')}}</h3>
                </div>
                <router-link :to="`/${this.$route.params.lang}/Applicationforpromotion`" class="promotion">
                    {{$t('OrganizingActivities.applyRecommend')}}
                </router-link>
            </div>
            <div class="Recommend_warp">
                <app-swiper>
                    <app-swiper-item class="box-Warp">
                        <ul>
                            <li>
                                <div class="top">
                                    <img src="/images/393346D8.jpg" alt="">
                                </div>
                                <div class="main">
                                    <h3>{{$t('ProductRecommendation.RecommendWarp.boxWarp0.title')}}</h3>
                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp0.company')}}</p>
                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp0.time')}}</p>
                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp0.place')}}</p>
                                </div>
                            </li>
                            <li>
                                <div class="top">
                                    <img src="/images/B3651298.jpg" alt="">
                                </div>
                                <div class="main">
                                    <h3>{{$t('ProductRecommendation.RecommendWarp.boxWarp1.title')}}</h3>
                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp1.company')}}</p>
                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp1.time')}}</p>
                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp1.place')}}</p>
                                </div>
                            </li>
                            <li>
                                <div class="top">
                                    <img src="/images/5A683DDA.jpg" alt="">
                                </div>
                                <div class="main">
                                    <h3>{{$t('ProductRecommendation.RecommendWarp.boxWarp2.title')}}</h3>
                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp2.company')}}</p>
                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp2.time')}}</p>
                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp2.place')}}</p>
                                </div>
                            </li>

                        </ul>
                    </app-swiper-item>
                    <!--                    <app-swiper-item class="box-Warp">-->
                    <!--                        <ul>-->
                    <!--                            <li>-->
                    <!--                                <div class="top">-->
                    <!--                                    <img src="/images/imgUrl0.png" alt="">-->
                    <!--                                </div>-->
                    <!--                                <div class="main">-->
                    <!--                                    <h3>{{$t('ProductRecommendation.RecommendWarp.boxWarp0.title')}}</h3>-->
                    <!--                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp0.company')}}</p>-->
                    <!--                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp0.time')}}</p>-->
                    <!--                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp0.place')}}</p>-->
                    <!--                                </div>-->
                    <!--                            </li>-->
                    <!--                            <li>-->
                    <!--                                <div class="top">-->
                    <!--                                    <img src="/images/imgUrl1.png" alt="">-->
                    <!--                                </div>-->
                    <!--                                <div class="main">-->
                    <!--                                    <h3>{{$t('ProductRecommendation.RecommendWarp.boxWarp1.title')}}</h3>-->
                    <!--                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp1.company')}}</p>-->
                    <!--                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp1.time')}}</p>-->
                    <!--                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp1.place')}}</p>-->
                    <!--                                </div>-->
                    <!--                            </li>-->
                    <!--                            <li>-->
                    <!--                                <div class="top">-->
                    <!--                                    <img src="/images/imgUrl2.png" alt="">-->
                    <!--                                </div>-->
                    <!--                                <div class="main">-->
                    <!--                                    <h3>{{$t('ProductRecommendation.RecommendWarp.boxWarp2.title')}}</h3>-->
                    <!--                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp2.company')}}</p>-->
                    <!--                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp2.time')}}</p>-->
                    <!--                                    <p>{{$t('ProductRecommendation.RecommendWarp.boxWarp2.place')}}</p>-->
                    <!--                                </div>-->
                    <!--                            </li>-->

                    <!--                        </ul>-->
                    <!--                    </app-swiper-item>-->
                </app-swiper>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        }
    }
</script>

<style lang="scss" scoped>
    .ProductRecommendation {
        width: 100%;
    }

    .product-recommendation-container {
        @include design-width-setting();
        margin: 0 auto;
    }

    .ProductRecommendation_Warp {
        margin: 0 auto 40px;
        display: flex;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        justify-content: space-between;
        padding-top: 66px;
    }

    .title {
        @include titles();

        padding-top: 30px;

        h3 {
            font-size: 26px;
            font-weight: 600;
            color: #333333;

        }
    }

    .promotion {
        min-width: 136px;
        padding: 0 5px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #ffffff;
        border-radius: 6px;
        background: #FAD214;
        display: block;

    }

    .Recommend_warp {
        width: 100%;
        padding-bottom: 120px;
    }

    .swiper-container {
        width: 100%;
        padding-bottom: 10px;
    }

    .box-Warp {
        width: 100%;
        justify-content: space-between;

        ul {
            display: flex;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            justify-content: space-between;

            li {
                width: 32%;
                height: 100%;
                // flex: 1;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);

                .top {
                    width: 100%;
                    /*height: 348px;*/

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .main {
                    padding: 20px 0 25px 56px;
                    box-sizing: border-box;
                    background: rgba(255, 255, 255, 1);
                    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);

                    h3 {
                        font-weight: 400;
                        color: rgba(51, 51, 51, 1);
                        margin-bottom: 10px;
                    }

                    p {
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        line-height: 26px;
                    }
                }
            }
        }
    }

    .en {
        .box-Warp ul li .main {
            padding: 20px 5px;
            word-break: break-all;
        }

        .box-Warp ul li .main p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .box-Warp ul li .main h3 {
            min-height: 38px;
            
            @include sm {
                min-height: auto;
            }
        }
    }

    @include sm() {
        .zh, .en {
            .box-Warp {
                ul {
                    display: block;

                    li {
                        width: 100%;

                        .main {
                            padding: 10px px2rem(20) 20px;
                        }
                    }
                }
            }
        }

        .ProductRecommendation_Warp {
            align-items: flex-end;
        }
    }
</style>