var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "MainActivity", style: _vm.BgUrl }, [
    _c("div", { staticClass: "Activity" }, [
      _c("div", { staticClass: "title" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("Activity.Title")))])
      ])
    ]),
    _c("div", { staticClass: "ActivityContent" }, [
      _c("div", { ref: "top", staticClass: "top" }, [
        _c(
          "ul",
          { ref: "elementUL" },
          _vm._l(_vm.ActivityList, function(item, index) {
            return _c(
              "li",
              {
                key: item.id,
                class: { show: index == _vm.selectAction },
                on: {
                  mouseenter: function($event) {
                    return _vm.Activityswitching(index)
                  }
                }
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t(item.list)) +
                    "\n                "
                )
              ]
            )
          }),
          0
        )
      ]),
      _vm.currentPageData && _vm.currentPageData.length
        ? _c(
            "div",
            { staticClass: "main_Activity" },
            [
              _c(
                "app-swiper",
                {
                  staticClass: "Activity_list",
                  attrs: { enablePagination: true }
                },
                _vm._l(_vm.currentPageData, function(item) {
                  return _c(
                    "app-swiper-item",
                    { key: item.id, staticClass: "swiper_item" },
                    [
                      _c("div", { staticClass: "left", style: _vm.bgimgs }, [
                        _c("div", { staticClass: "titles" }, [
                          _c("h3", [_vm._v(_vm._s(item.title) + " ")]),
                          item.subTitle
                            ? _c("h4", [_vm._v(_vm._s(item.subTitle))])
                            : _vm._e(),
                          _c("span")
                        ]),
                        item.dateDisplay
                          ? _c("div", { staticClass: "time" }, [
                              _c("h2", [
                                _vm._v(_vm._s(_vm.$t("Activity.Time")) + "：")
                              ]),
                              _c("p", [_vm._v(_vm._s(item.dateDisplay))])
                            ])
                          : _vm._e(),
                        item.place
                          ? _c("div", { staticClass: "place" }, [
                              _c("h2", [
                                _vm._v(
                                  _vm._s(_vm.$t("Activity.Address")) + "："
                                )
                              ]),
                              _c("p", [_vm._v(_vm._s(item.place))])
                            ])
                          : _vm._e(),
                        item.owner
                          ? _c("div", { staticClass: "Sponsorunit" }, [
                              _c("h2", [
                                _vm._v(
                                  _vm._s(_vm.$t("Footer.GuidanceContent1")) +
                                    "："
                                )
                              ]),
                              _c("p", [_vm._v(" " + _vm._s(item.owner))])
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "Brightspot" }, [
                          _c("h2", [
                            _vm._v(_vm._s(_vm.$t("Activity.Highlight")) + "：")
                          ]),
                          _c("p", {
                            domProps: { innerHTML: _vm._s(item.subject) }
                          })
                        ]),
                        _c(
                          "a",
                          {
                            staticClass: "Understandingdetails",
                            attrs: {
                              href:
                                "/" +
                                _vm.$i18n.locale +
                                "/ActivityDetails/" +
                                item.id
                            }
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.$t("confer.learnMore")) +
                                "\n                        "
                            )
                          ]
                        )
                      ]),
                      _c("div", {
                        staticClass: "right",
                        style: {
                          backgroundImage: "url('" + item.cover + "')"
                        }
                      })
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }