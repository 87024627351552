<template>
    <div class="Bannerion">
        <app-swiper :enablePagination="true" class="warp_box">
<!--            <app-swiper-item class="box">-->
<!--                <div class="box-item">-->
<!--                    <img src="/images/pic_banner@2x.jpg" alt/>-->
<!--                    <div class="position">-->
<!--                        <a :href="link.join" class="banner-link"></a>-->
<!--                        <a :href="link.visit" class="banner-link"></a>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </app-swiper-item>-->
<!--            <app-swiper-item class="box">-->
<!--                <div class="box-item">-->
<!--                    <img src="/images/Exhibitorsservice/pic_zhanshangfuwu@2x.jpg" alt/>-->
<!--                </div>-->
<!--            </app-swiper-item>-->
<!--            <app-swiper-item class="box">-->
<!--                <div class="box-item">-->
<!--                    <img src="/images/Buyerservice/bgdua.jpg" alt/>-->
<!--                </div>-->
<!--            </app-swiper-item>-->
            <app-swiper-item class="box" v-for="banner in bannerList" v-key="banner">
                <div class="box-item">
                    <img :src="banner" alt/>
                </div>
            </app-swiper-item>
<!--            <app-swiper-item class="box">-->
<!--                <div class="box-item">-->
<!--                    <img :src="bannerList[3]" alt/>-->
<!--                </div>-->
<!--            </app-swiper-item>-->
        </app-swiper>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                link: {
                    join: `/${this.$route.params.lang}/exhibitorsService#exhibition`,
                    visit: `/${this.$route.params.lang}/buyerservice#RegistrationOfExhibitors`,
                }
            };
        },
        mounted() {

        }
    };
</script>
<style lang="scss" scoped>
    .Bannerion {
        width: 100%;

        .warp_box {
            width: 100%;

            .box {
                width: 100%;

                img {
                    width: 100%;
                }
            }
        }
    }

    .box-item {
        position: relative;

        .position {
            position: absolute;
            top: 449 / 700 * 100%;
            width: 100%;
            height: 0;
            text-align: center;
            margin-top: -2px;

            > a {
                display: inline-block;
                margin: 0 px2rem(68 / 2);
                width: px2rem(254);
                height: px2rem(73);
                /*background: yellow;*/
            }
        }
    }

</style>
