<template>
    <div class="Conferenceact">
       
        <Bannerpice/>
        <MainActivity id="MainActivity"/>
        <Organizingactivities id="Organizingactivities"/>
        <ProductRecommendation id="ProductRecommendation"/>
        <Footer/>
    </div>
</template>
<script>

import Bannerpice from './children/Bannerpice' //轮播图
import MainActivity from './children/MainActivity'  //活动
import Organizingactivities from './children/Organizingactivities'  //筹办活动
import ProductRecommendation from './children/ProductRecommendation' //产品推介
import Footer from '../home/children/Footer'  //底部
export default {
    components:{
        Bannerpice,
        MainActivity,
        Organizingactivities,
        ProductRecommendation,
        Footer
    }
}
</script>
<style lang="less" scoped>

</style>