var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Conferenceact" },
    [
      _c("Bannerpice"),
      _c("MainActivity", { attrs: { id: "MainActivity" } }),
      _c("Organizingactivities", { attrs: { id: "Organizingactivities" } }),
      _c("ProductRecommendation", { attrs: { id: "ProductRecommendation" } }),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }