import { render, staticRenderFns } from "./ProductRecommendation.vue?vue&type=template&id=7ef73c18&scoped=true&"
import script from "./ProductRecommendation.vue?vue&type=script&lang=js&"
export * from "./ProductRecommendation.vue?vue&type=script&lang=js&"
import style0 from "./ProductRecommendation.vue?vue&type=style&index=0&id=7ef73c18&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ef73c18",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/wwwroot/conmart-expo-website/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7ef73c18')) {
      api.createRecord('7ef73c18', component.options)
    } else {
      api.reload('7ef73c18', component.options)
    }
    module.hot.accept("./ProductRecommendation.vue?vue&type=template&id=7ef73c18&scoped=true&", function () {
      api.rerender('7ef73c18', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/conferenceactivities/children/ProductRecommendation.vue"
export default component.exports