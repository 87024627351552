var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Bannerion" },
    [
      _c(
        "app-swiper",
        { staticClass: "warp_box", attrs: { enablePagination: true } },
        _vm._l(_vm.bannerList, function(banner) {
          return _c(
            "app-swiper-item",
            {
              directives: [
                {
                  name: "key",
                  rawName: "v-key",
                  value: banner,
                  expression: "banner"
                }
              ],
              staticClass: "box"
            },
            [
              _c("div", { staticClass: "box-item" }, [
                _c("img", { attrs: { src: banner, alt: "" } })
              ])
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }