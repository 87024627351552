<template>
    <div class="MainActivity" :style="BgUrl">
        <div class="Activity">
            <div class="title">
                <h3>{{$t('Activity.Title')}}</h3>
            </div>
        </div>
        <div class="ActivityContent">
            <div class="top" ref="top">
                <ul ref="elementUL">
                    <li v-for="(item,index) in  ActivityList" :key="item.id"
                        :class="{show:index == selectAction}"
                        @mouseenter="Activityswitching(index)">
                        {{$t(item.list)}}
                    </li>
                </ul>
            </div>
            <div class="main_Activity" v-if="currentPageData&&currentPageData.length">
                <app-swiper class="Activity_list" :enablePagination="true">
                    <app-swiper-item class="swiper_item" v-for="item in currentPageData" :key="item.id">
                        <div class="left" :style="bgimgs">
                            <div class="titles">
                                <h3>{{item.title}} </h3>
                                <h4 v-if="item.subTitle">{{item.subTitle}}</h4>
                                <span></span>
                            </div>
                            <div class="time" v-if="item.dateDisplay">
                                <h2>{{$t('Activity.Time')}}：</h2>
                                <p>{{item.dateDisplay}}</p>
                            </div>
                            <div class="place" v-if="item.place">
                                <h2>{{$t('Activity.Address')}}：</h2>
                                <p>{{item.place}}</p>
                            </div>
                            <div class="Sponsorunit" v-if="item.owner">
                                <h2>{{$t('Footer.GuidanceContent1')}}：</h2>
                                <p> {{item.owner}}</p>
                            </div>
                            <div class="Brightspot">
                                <h2>{{$t('Activity.Highlight')}}：</h2>
                                <p v-html="item.subject"></p>
                            </div>
                            <a class="Understandingdetails" :href="`/${$i18n.locale}/ActivityDetails/${item.id}`">
                                {{$t('confer.learnMore')}}
                            </a>
                        </div>
                        <div class="right" :style="{
                            backgroundImage:`url('${item.cover}')`
                        }">
                            <!--<img :src="item.cover" alt="">-->
                        </div>
                    </app-swiper-item>
                </app-swiper>
            </div>
        </div>
    </div>
</template>

<script>
    import {getExpActivity} from '@/publicApi/expoActivity'
    import moment from 'moment'

    export default {
        data() {
            return {
                ActivityList: [
                    {id: 'A', list: 'ExhibitionActivities.ActivityList.Title0'},
                    {id: 'B', list: 'ExhibitionActivities.ActivityList.Title1'},
                    {id: 'C', list: 'ExhibitionActivities.ActivityList.Title2'},
                    {id: 'D', list: 'ExhibitionActivities.ActivityList.Title3'},
                    {id: 'E', list: 'ExhibitionActivities.ActivityList.Title4'},
                ],
                selectAction: 0,
                leftimg: {
                    imgUrl: '/images/activity/pic_tuijie_1@2x.png',
                },
                rightimg: {
                    imgUrl: '/images/activity/pic_tuijie_1@2x.png',
                },
                currentPageData: [],
                bgimgs: {
                    backgroundImage: "url(/images/Exhibitorsservice/bg_ditu_2@2x.png)",
                    backgroundRepeat: "no-repeat",
                },
                BgUrl: {
                    backgroundImage: "url(/images/activity/bg_ditu_6@2x.png),url(/images/activity/bg_xian_1@2x.png)",
                    backgroundRepeat: 'no-repeat,no-repeat'
                }
            }
        },
        methods: {
            Activityswitching(index) {
                this.selectAction = index;
                this.getCurrentActivityList();
            },
            async getCurrentActivityList() {
                let A = this.ActivityList[this.selectAction].id;
                let {
                    data,
                    status,
                    msg
                } = await getExpActivity({
                    category: A,
                    lang: this.$transformAPILang(),
                    type: 0
                });
                let newData = data.map(item => ({
                    id: item.id,  //活动ID
                    startTime: item.startTime,   //开始时间
                    endTime: item.endTime, //结束时间
                    place: item.contents[0].place,  //场地
                    owner: item.contents[0].owner,  //主办方
                    subject: item.contents[0].subject,//内容
                    title: item.contents[0].title,//主标题
                    subTitle: item.contents[0].subTitle,  //副标题
                    cover: (item.contents[0].thumb || '').replace(/!thumb$/, "!thumb_activity"), //缩放图
                    dateDisplay: !item.startTime ? "" : moment(item.startTime).isSame(item.endTime, 'day') ? `${moment(item.startTime).format('YYYY-MM-DD HH:mm')}~${moment(item.endTime).format('HH:mm')}`
                        : `开始时间 : ${moment(item.startTime).format('YYYY-MM-DD HH:mm')},   结束时间 : ${moment(item.endTime).format('YYYY-MM-DD HH:mm')}`
                }));
                this.currentPageData = newData;
            },
            gotoDetails(id) {
                this.$router.push({path: `/${this.$i18n.local}/Activitydetails/${id}`})
            }
        },
        mounted() {
            const top = this.$refs.top;
            const ul = this.$refs.elementUL;

            if (top.style) top.style.height = ul.clientHeight;
            this.Activityswitching(0)
        }
    }
</script>

<style lang="scss" scoped>
    .MainActivity {
        width: 100%;
        background-position: 0 124px;
    }

    .Activity {
        margin: auto;
        padding-top: 50px;

        @include design-width-setting;
    }

    .title {
        @include titles();

        margin-bottom: 30px;
        padding-bottom: 3px;
        padding-top: 50px;
        line-height: 32px;

        h3 {
            font-size: 26px;
            font-weight: 600;
            color: rgba(51, 51, 51, 1);
        }
    }

    .ActivityContent {
        @include design-width-setting;
        margin: 0 auto;
        height: 600px;
        background: #F6F6F6;
        padding-top: 32px;
        padding-right: 10px;
        box-sizing: border-box;

        .top {
            height: auto;
            /*height: 44px;*/
            width: 100%;
            display: flex;
            justify-content: flex-end;
            // overflow: hidden;
            position: relative;

            ul {
                width: calc(64% - 30px);
                /*position: absolute;*/
                /*right: 0;*/
                /*left: calc(36% + 30px);*/
                min-height: 44px;
                border-bottom: 1px solid #CCCCCC;

                li {
                    display: table-cell;
                    vertical-align: middle;
                    width: 20%;
                    min-height: 44px;
                    box-sizing: border-box;
                    line-height: 18px;
                    padding: #{(44-18) / 2}px 0;
                    text-align: center;
                    font-weight: 600;
                    color: rgba(102, 102, 102, 1);
                    cursor: pointer;
                    transition: .2s linear;

                    &.show {
                        background: #FAD214;
                        color: #FFFFFF;
                    }
                }
            }
        }

        .Activity_list {
            padding-bottom: 40px;
        }

        .swiper_item {
            display: flex;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;

            .left {
                padding-left: 66px;
                box-sizing: border-box;
                width: 36%;
                background-position-y: 197px;
                background-position-x: 13px;
                margin-right: 30px;
                flex-shrink: 0;

                .titles {
                    min-width: 200px;
                    display: inline-block;
                    padding-right: 10px;

                    h3 {
                        font-weight: bold;
                        color: rgba(102, 102, 102, 1);
                        font-size: 26px;
                    }

                    h4 {
                        font-weight: 500;
                        color: rgba(102, 102, 102, 1);
                        font-size: 17px;
                        line-height: 40px;
                        margin-bottom: 5px;
                    }

                    span {
                        display: block;
                        width: 36px;
                        height: 6px;
                        background: rgba(250, 210, 20, 1);
                    }
                }

                .time {
                    margin-top: 30px;

                    h2 {
                        font-weight: 500;
                        color: black;
                        font-size: 16px;
                        margin-bottom: 5px;
                    }

                    p {
                        font-size: 16px;
                        color: rgba(102, 102, 102, 1);
                        font-weight: 400;
                        width: 55%;
                    }
                }

                .place {
                    margin-top: 30px;

                    h2 {
                        font-weight: 500;
                        color: black;
                        font-size: 16px;
                        margin-bottom: 5px;
                    }

                    p {
                        font-size: 16px;
                        color: rgba(102, 102, 102, 1);
                        font-weight: 400;
                    }
                }

                .Sponsorunit {
                    margin-top: 30px;

                    h2 {
                        font-weight: 500;
                        color: black;
                        font-size: 16px;
                        margin-bottom: 5px;
                    }

                    p {
                        font-size: 16px;
                        color: rgba(102, 102, 102, 1);
                        font-weight: 400;
                    }
                }

                .Brightspot {
                    margin-top: 30px;

                    h2 {
                        font-weight: 500;
                        color: black;
                        font-size: 16px;
                        margin-bottom: 5px;
                    }

                    p {
                        font-size: 16px;
                        color: rgba(102, 102, 102, 1);
                        font-weight: 400;
                        width: 250px;
                    }
                }

                .Understandingdetails {
                    display: block;
                    width: 110px;
                    padding: 0 5px;
                    height: 34px;
                    background: rgba(250, 210, 20, 1);
                    border-radius: 6px;
                    line-height: 34px;
                    text-align: center;
                    font-weight: 400;
                    color: rgba(253, 253, 253, 1);
                    font-size: 14px;
                    margin-top: 50px;
                    cursor: pointer;
                }
            }

            .right {
                width: 820px;
                height: 456px;
                margin-top: 10px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;

                img {
                    width: 100%;
                }
            }
        }
    }

    @include md() {

        .ActivityContent {
            .top ul {
                left: calc(36% + 10px);
                font-size: 12px;
            }

            .swiper_item {
                .left {
                    // 66px;
                    padding-left: 16px;
                    margin-right: 10px;
                }
            }
        }
    }

    @include sm {
        .ActivityContent {
            padding: 0;
            height: auto;

            .top ul {
                width: 100%;
                left: 0;
                right: 0;
            }

            .main_Activity {
                padding-top: 20px;

            }

            .swiper_item {
                display: block;

                .left {
                    width: 100%;
                }

                .right {
                    width: 100%;
                }
            }
        }
    }
</style>
