var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Organizing " }, [
    _c("div", { staticClass: "Organizing_contain" }, [
      _c("div", { staticClass: "titles" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("OrganizingActivities.Title")))])
      ]),
      _c("div", { staticClass: "main_activities" }, [
        _c("div", { staticClass: "Technological" }, [
          _c("ul", { staticClass: "piclist" }, [
            _c("li", { staticClass: "list_item" }, [
              _c("img", { attrs: { src: "/images/05F31D8F.png", alt: "" } }),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("OrganizingActivities.Technological0")))
              ])
            ]),
            _c("li", { staticClass: "list_item" }, [
              _c("img", { attrs: { src: "/images/638FD1F2.png", alt: "" } }),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("OrganizingActivities.Technological1")))
              ])
            ]),
            _c("li", { staticClass: "list_item" }, [
              _c("img", { attrs: { src: "/images/30CD61F2.png", alt: "" } }),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("OrganizingActivities.Technological2")))
              ])
            ])
          ])
        ]),
        _vm._m(0)
      ]),
      _c("div", { staticClass: "Preparefordeparture" }, [
        _c("ul", { staticClass: "listWarp" }, [
          _c("li", { staticClass: "list_itemn" }, [
            _vm._m(1),
            _c("div", { staticClass: "main_text" }, [
              _c(
                "div",
                { staticClass: "main_warp", class: _vm.$route.params.lang },
                [
                  _c("h3", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "OrganizingActivities.Preparefordeparture.listItemn0.Text.title"
                        )
                      )
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "OrganizingActivities.Preparefordeparture.listItemn0.Text.text"
                        )
                      )
                    )
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "main_theme" }, [
              _c("h3", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "OrganizingActivities.Preparefordeparture.listItemn0.Theme.theme"
                    )
                  )
                )
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "OrganizingActivities.Preparefordeparture.listItemn0.Theme.content"
                    )
                  )
                )
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "OrganizingActivities.Preparefordeparture.listItemn0.Theme.time"
                    )
                  )
                )
              ])
            ])
          ]),
          _c("li", { staticClass: "list_itemn" }, [
            _vm._m(2),
            _c("div", { staticClass: "main_text" }, [
              _c(
                "div",
                { staticClass: "main_warp", class: _vm.$route.params.lang },
                [
                  _c("h3", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "OrganizingActivities.Preparefordeparture.listItemn1.Text.title"
                        )
                      )
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "OrganizingActivities.Preparefordeparture.listItemn1.Text.text"
                        )
                      )
                    )
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "main_theme" }, [
              _c("h3", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "OrganizingActivities.Preparefordeparture.listItemn1.Theme.theme"
                    )
                  )
                )
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "OrganizingActivities.Preparefordeparture.listItemn1.Theme.content"
                    )
                  )
                )
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "OrganizingActivities.Preparefordeparture.listItemn1.Theme.time"
                    )
                  )
                )
              ])
            ])
          ]),
          _c("li", { staticClass: "list_itemn" }, [
            _vm._m(3),
            _c("div", { staticClass: "main_text" }, [
              _c(
                "div",
                { staticClass: "main_warp", class: _vm.$route.params.lang },
                [
                  _c("h3", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "OrganizingActivities.Preparefordeparture.listItemn2.Text.title"
                        )
                      )
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "OrganizingActivities.Preparefordeparture.listItemn2.Text.text"
                        )
                      )
                    )
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "main_theme" }, [
              _c("h3", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "OrganizingActivities.Preparefordeparture.listItemn2.Theme.theme"
                    )
                  )
                )
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "OrganizingActivities.Preparefordeparture.listItemn2.Theme.content"
                    )
                  )
                )
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "OrganizingActivities.Preparefordeparture.listItemn2.Theme.time"
                    )
                  )
                )
              ])
            ])
          ]),
          _c(
            "li",
            { staticClass: "list_itemn" },
            [
              _vm._m(4),
              _c(
                "router-link",
                {
                  staticClass: "activity_theme",
                  attrs: {
                    to: "/" + this.$route.params.lang + "/Organizingactivities"
                  }
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("OrganizingActivities.Apply")) +
                      "\n                    "
                  )
                ]
              ),
              _c("div", { staticClass: "activity_content" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("OrganizingActivities.ApplicationContent")) +
                    "\n                    "
                )
              ])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Progressbar" }, [
      _c("div", { staticClass: "Realization" }),
      _c("div", { staticClass: "imgUrl", staticStyle: { margin: "0 26px" } }, [
        _c("img", {
          attrs: {
            src: "/images/Exhibitorsservice/icon_gou_disabled@2x.png",
            alt: ""
          }
        })
      ]),
      _c("div", { staticClass: "Dottedline1" }),
      _c("div", { staticClass: "imgUrl", staticStyle: { margin: "0 26px" } }, [
        _c("img", {
          attrs: {
            src: "/images/Exhibitorsservice/icon_gou_disabled@2x.png",
            alt: ""
          }
        })
      ]),
      _c("div", { staticClass: "Dottedline2" }),
      _c("div", { staticClass: "imgUrl", staticStyle: { margin: "0 26px" } }, [
        _c("img", {
          attrs: {
            src: "/images/Exhibitorsservice/icon_gou_disabled@2x.png",
            alt: ""
          }
        })
      ]),
      _c("div", { staticClass: "Dottedlines" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("img", { attrs: { src: "/images/A7CB0692.jpg", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("img", { attrs: { src: "/images/EFA2A835.jpg", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("img", { attrs: { src: "/images/D4CF2B64.jpg", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("img", { attrs: { src: "/images/gn412f.png", alt: "" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }