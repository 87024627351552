<template>
    <div class="Organizing ">
        <div class="Organizing_contain">
            <div class="titles">
                <!--筹办活动-->
                <h3>{{$t('OrganizingActivities.Title')}}</h3>
            </div>
            <div class="main_activities">
                <div class="Technological">
                    <ul class="piclist">
                        <li class="list_item">
                            <img src="/images/05F31D8F.png" alt/>
                            <p>{{$t('OrganizingActivities.Technological0')}}</p>
                        </li>
                        <li class="list_item">
                            <img src="/images/638FD1F2.png" alt/>
                            <p>{{$t('OrganizingActivities.Technological1')}}</p>
                        </li>
                        <li class="list_item">
                            <img src="/images/30CD61F2.png" alt/>
                            <p>{{$t('OrganizingActivities.Technological2')}}</p>
                        </li>
                    </ul>
                </div>
                <div class="Progressbar">
                    <div class="Realization"></div>
                    <div class="imgUrl" style="margin:0 26px">
                        <img src="/images/Exhibitorsservice/icon_gou_disabled@2x.png" alt/>
                    </div>
                    <div class="Dottedline1"></div>
                    <div style="margin:0 26px" class="imgUrl">
                        <img src="/images/Exhibitorsservice/icon_gou_disabled@2x.png" alt/>
                    </div>
                    <div class="Dottedline2"></div>
                    <div style="margin:0 26px" class="imgUrl">
                        <img src="/images/Exhibitorsservice/icon_gou_disabled@2x.png" alt/>
                    </div>
                    <div class="Dottedlines"></div>
                </div>
            </div>
            <div class="Preparefordeparture">
                <ul class="listWarp">
                    <li class="list_itemn">
                        <div class="top"><img src="/images/A7CB0692.jpg" alt=""></div>
                        <div class="main_text">
                            <div class="main_warp" :class="$route.params.lang">
                                <h3>{{$t('OrganizingActivities.Preparefordeparture.listItemn0.Text.title')}}</h3>
                                <p>{{$t('OrganizingActivities.Preparefordeparture.listItemn0.Text.text')}}</p>
                            </div>
                        </div>
                        <div class="main_theme">
                            <h3>{{$t('OrganizingActivities.Preparefordeparture.listItemn0.Theme.theme')}}</h3>
                            <p>{{$t('OrganizingActivities.Preparefordeparture.listItemn0.Theme.content')}}</p>
                            <p>{{$t('OrganizingActivities.Preparefordeparture.listItemn0.Theme.time')}}</p>
                        </div>
                    </li>
                    <li class="list_itemn">
                        <div class="top"><img src="/images/EFA2A835.jpg" alt=""></div>
                        <div class="main_text">
                            <div class="main_warp" :class="$route.params.lang">
                                <h3>{{$t('OrganizingActivities.Preparefordeparture.listItemn1.Text.title')}}</h3>
                                <p>{{$t('OrganizingActivities.Preparefordeparture.listItemn1.Text.text')}}</p>
                            </div>
                        </div>
                        <div class="main_theme">
                            <h3>{{$t('OrganizingActivities.Preparefordeparture.listItemn1.Theme.theme')}}</h3>
                            <p>{{$t('OrganizingActivities.Preparefordeparture.listItemn1.Theme.content')}}</p>
                            <p>{{$t('OrganizingActivities.Preparefordeparture.listItemn1.Theme.time')}}</p>
                        </div>
                    </li>
                    <li class="list_itemn">
                        <div class="top"><img src="/images/D4CF2B64.jpg" alt=""></div>
                        <div class="main_text">
                            <div class="main_warp" :class="$route.params.lang">
                                <h3>{{$t('OrganizingActivities.Preparefordeparture.listItemn2.Text.title')}}</h3>
                                <p>{{$t('OrganizingActivities.Preparefordeparture.listItemn2.Text.text')}}</p>
                            </div>
                        </div>
                        <div class="main_theme">
                            <h3>{{$t('OrganizingActivities.Preparefordeparture.listItemn2.Theme.theme')}}</h3>
                            <p>{{$t('OrganizingActivities.Preparefordeparture.listItemn2.Theme.content')}}</p>
                            <p>{{$t('OrganizingActivities.Preparefordeparture.listItemn2.Theme.time')}}</p>
                        </div>
                    </li>
                    <li class="list_itemn">
                        <div class="top">
                            <img src="/images/gn412f.png" alt="">
                        </div>
                        <router-link :to="`/${this.$route.params.lang}/Organizingactivities`" class="activity_theme">
                            {{$t('OrganizingActivities.Apply')}}
                        </router-link>
                        <div class="activity_content">
                            {{$t('OrganizingActivities.ApplicationContent')}}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        }
    }
</script>

<style lang="scss" scoped>
    .Organizing {
        width: 100%;
        background: #F6F6F6;
        padding-bottom: 56px;
    }

    .Organizing_contain {
        margin: auto;
        padding-top: 64px;
        box-sizing: border-box;
        @include design-width-setting;

        .titles {
            @include titles();

            h3 {
                font-size: 26px;
                font-weight: 600;
                color: #333333;
            }
        }
    }

    .Mainbox {
        width: 100%;
        min-height: 660px;
        background: #fff;
    }

    .listWarp {
        display: flex;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        justify-content: space-between;

        .list_itemn {
            width: 24.1%;
            border: 1px solid #D8D8D8;

            .top {
                width: 100%;

                img {
                    width: 100%;
                }
            }

            .main_text {
                padding: 20px 25px 0 25px;


                .main_warp {
                    min-height: 90px;
                    border-bottom: 1px dashed #D8D8D8;

                    &.en{
                        h3{
                            min-height: 69px;
                        }
                        
                        p{
                            min-height: 56px;
                        }
                    }
                    
                    h3 {
                        font-weight: 600;
                        color: rgba(51, 51, 51, 1);
                        font-size: 20px;
                        min-height: 52px;
                    }

                    p {
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        font-size: 14px;
                        line-height: 18px;
                        padding: 9px 0 11px;
                    }
                }
            }

            .main_theme {
                padding: 14px 25px 32px 25px;

                h3 {
                    font-weight: 600;
                    color: rgba(51, 51, 51, 1);
                    font-size: 20px;
                }

                p {
                    font-weight: 400;
                    color: rgba(102, 102, 102, 1);
                    font-size: 14px;
                    line-height: 26px;
                }
            }

            .activity_theme {
                width: 144px;
                height: 44px;
                line-height: 44px;
                text-align: center;
                margin: auto;
                color: #ffffff;
                border-radius: 6px;
                background: #FAD214;
                margin-top: 62px;
                display: block;
            }

            .activity_content {
                width: 208px;
                margin: auto;
                font-size: 14px;
                color: #666666;
                line-height: 28px;
                margin-top: 18px;

            }
        }

    }

    @include md() {
        .listWarp {
            flex-wrap: wrap;

            .list_itemn {
                width: 4.3rem;
                margin: 0 .05rem 20px;
                box-sizing: border-box;
            }
        }
    }

    @include sm() {
        .listWarp {
            flex-wrap: wrap;

            .list_itemn {
                width: 100%;
                box-sizing: border-box;
            }
        }
    }
</style>

<style lang="scss" scoped>
    //已修正完了的

    .piclist {
        padding: 60px 42px 0 42px;
        box-sizing: border-box;
        display: flex;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        justify-content: space-between;

        .list_item {
            text-align: center;
            margin: 0 auto auto;
            width: 126px;

            img {
                width: px2rem(160);
                height: px2rem(160);
                max-width: 80px;
                max-height: 80px;
                min-width: 40px;
                min-height: 40px;
            }

            p {
                margin-top: 8px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
            }
        }
    }

    .Progressbar {
        margin: 10px 42px 0;
        display: flex;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            border-bottom: 2px dashed #D8D8D8;

        }

        .imgUrl {
            width: 33.3333%;
            height: px2rem(160);
            max-height: 80px;
            min-height: 30px;
            display: list-item;
            margin: 0 auto;
            position: relative;
            z-index: 1;
            text-align: center;

            &:before {
                content: '';
                position: absolute;
                width: px2rem(80);
                height: px2rem(80);
                display: block;
                background: #f6f6f6;
                top: 50%;
                left: 50%;
                margin-left: px2rem(-40);
                margin-top: px2rem(-40);
                z-index: 0;
            }

            &:after {
                display: inline-block;
                height: 100%;
                vertical-align: middle;
                content: '';
            }

            img {
                width: px2rem(60);
                height: px2rem(60);
                max-width: 30px;
                max-height: 30px;
                min-width: 20px;
                background: #f6f6f6;
                min-height: 20px;
                vertical-align: middle;
                position: relative;
                z-index: 1;
            }
        }
    }
</style>