var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ProductRecommendation",
      class: {
        en: _vm.$route.params.lang === "en",
        zh: _vm.$route.params.lang === "zh"
      }
    },
    [
      _c("div", { staticClass: "product-recommendation-container" }, [
        _c(
          "div",
          { staticClass: "ProductRecommendation_Warp" },
          [
            _c("div", { staticClass: "title" }, [
              _c("h3", [_vm._v(_vm._s(_vm.$t("ProductRecommendation.Title")))])
            ]),
            _c(
              "router-link",
              {
                staticClass: "promotion",
                attrs: {
                  to: "/" + this.$route.params.lang + "/Applicationforpromotion"
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("OrganizingActivities.applyRecommend")) +
                    "\n            "
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "Recommend_warp" },
          [
            _c(
              "app-swiper",
              [
                _c("app-swiper-item", { staticClass: "box-Warp" }, [
                  _c("ul", [
                    _c("li", [
                      _c("div", { staticClass: "top" }, [
                        _c("img", {
                          attrs: { src: "/images/393346D8.jpg", alt: "" }
                        })
                      ]),
                      _c("div", { staticClass: "main" }, [
                        _c("h3", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ProductRecommendation.RecommendWarp.boxWarp0.title"
                              )
                            )
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ProductRecommendation.RecommendWarp.boxWarp0.company"
                              )
                            )
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ProductRecommendation.RecommendWarp.boxWarp0.time"
                              )
                            )
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ProductRecommendation.RecommendWarp.boxWarp0.place"
                              )
                            )
                          )
                        ])
                      ])
                    ]),
                    _c("li", [
                      _c("div", { staticClass: "top" }, [
                        _c("img", {
                          attrs: { src: "/images/B3651298.jpg", alt: "" }
                        })
                      ]),
                      _c("div", { staticClass: "main" }, [
                        _c("h3", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ProductRecommendation.RecommendWarp.boxWarp1.title"
                              )
                            )
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ProductRecommendation.RecommendWarp.boxWarp1.company"
                              )
                            )
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ProductRecommendation.RecommendWarp.boxWarp1.time"
                              )
                            )
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ProductRecommendation.RecommendWarp.boxWarp1.place"
                              )
                            )
                          )
                        ])
                      ])
                    ]),
                    _c("li", [
                      _c("div", { staticClass: "top" }, [
                        _c("img", {
                          attrs: { src: "/images/5A683DDA.jpg", alt: "" }
                        })
                      ]),
                      _c("div", { staticClass: "main" }, [
                        _c("h3", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ProductRecommendation.RecommendWarp.boxWarp2.title"
                              )
                            )
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ProductRecommendation.RecommendWarp.boxWarp2.company"
                              )
                            )
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ProductRecommendation.RecommendWarp.boxWarp2.time"
                              )
                            )
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "ProductRecommendation.RecommendWarp.boxWarp2.place"
                              )
                            )
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }